import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.header`
  height: 70px;
  box-shadow: 0 3px 6px #00000029;
  background-color: ${props => props.theme.bg.primary};
  padding: 1rem 0 1rem 20px;
  position: sticky;
  top: 0;
  z-index: 10;
  /* overflow-x: hidden;
  overflow-y: visible; */

  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;

  ${mediaQuery.phone`
    z-index: 9999999991;

    display: flex;
    
    .header-nav {
      max-width: 600px;
      padding: 30px;
      position: absolute;
      box-sizing: border-box;
      top: 0;
      right: ${({ openMenu }) => openMenu ? '0px' : '-600px'};
      width: 100vw;
      height: 100vh;
      background-color: #23797B;
      transition: 0.4s;


      .header__button-mobile {
        margin-top: auto;
        border: 2px solid ${({ theme }) => theme.font.white};
        width: 100%;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .header__submenu-mobile {
        display: flex;
        justify-content: space-between;
      }

      .header__button-close {
        border: none;
      }

      .menu-list-header {
        flex-direction: column;
        align-items: flex-start;
        padding: 50px 0 30px;

        .is-opacity {
          opacity: 0.7;
        }

        li {
          margin-bottom: 20px;

          .ic-arrow {
            display: none;
          }
  
          .header__submenu {
            background: ${({ theme }) => theme.bg.second};
            position: relative;
            padding: 0px;
            top: 0px;
            left: 20px;
            width: 320px;
            box-shadow: none;
    
            li {
              margin-top: 20px;
              margin-bottom: 0px;
              padding: 0px;

              a {
                font-weight: 500;
                padding: 0px;
              }
            }
          }
  
          .header__submenu.is-hover {
            opacity: 1;
            transform: scale(1);
            z-index: 1;
          }
        }
      }
      
      li a {
        color: ${({ theme }) => theme.font.white};
        padding: 0px;
        font-weight: 500;
      }

    }

    .button-header {
      display: none;
    }

    .button-header-mobile {
      background: transparent;
      border: none;
    }

    .header__button-singin {
      width: 85%;
      height: 53px;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      position: absolute;
      bottom: 80px;
      margin: auto 0;
      border: 2px solid ${({ theme }) => theme.font.white};
    }
  `}


  ${mediaQuery.desktop`
    display: flex;
    padding: 1rem 1.4rem;

    .header-nav {
      display: flex;
      position: inherit!important;
      width: 100%;
      background-color: ${({ theme }) => theme.bg.primary};
      height: 70px;
      top: none;
      max-width: 100%;
      padding: 0px;

      .header__submenu-mobile {
        display: none!important;
      }

      .menu-list-header {
        flex-direction: row;
        flex: 1;
        justify-content: center;
        padding: 0px;

        .is-opacity {
          opacity: 1;
        }

        .ic-arrow {
          display: initial!important;
        }

        >li {
          padding: 23px 15px;
          margin: 0px;
        }

        li {
          .header__submenu {
            position: absolute;
            padding: 0px 30px 30px;
            top: 70px;
            left: -55px;
            width: 320px;
            background: ${({ theme }) => theme.bg.primary};
            box-shadow: 0px 3px 6px #00000029;
            transition: 0.2s;
        
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        
            li {
              padding-top: 30px;
              margin-top: 0px;
            }
          }
        }

        li .header__submenu.is-hover {
          opacity: 0;
          z-index: -10;
          transform: scale(0);
        }
      
        li:hover .header__submenu.is-hover {
          opacity: 1;
          z-index: 1;
          transform: scale(1);
        }

        .ic-arrow {
          display: block;
          margin-left: 10px;
          transition: 0.3s;
        }
      
        li:hover .ic-arrow {
          transform: rotateZ(180deg);
        }
      }
   
      li a {
        color: ${({ theme }) => theme.font.primary};
      }
    }

    .button-header-mobile {
      display: none;
    }
    


    .header__button-singin {
      width: 100px;
      height: 40px;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      position: relative;
      bottom: auto;
      margin: auto 0;
      border: none;
      border: 1px ${({ theme }) => theme.border.borderBtn} solid;
    }
  `}

  .header-logo {
    max-width: 110px;

    ${mediaQuery.desktop`
      max-width: 144px;
    `}

    img{
      width: 100%;
    }
  }

  .ic-arrow {
    margin-left: 10px;
    transition: 0.3s;
  }

  li:hover .ic-arrow {
    transform: rotateZ(180deg);
  }

  .menu-list-header {
    display: flex;
    align-items: center;

    li {
      position: relative;
    }

    a {
      padding: 1.4rem 1.8rem;
      color: ${({ theme }) => theme.font.primary};
      font: 16px 'Poppins', sans-serif;
      font-weight: 600;
      transition: color 0.3s;

      &:hover {
        color: ${({ theme }) => theme.font.third};
      }
    }
  }
`;
