import pdfCCBConditions from '../../../assets/pdf/condicoes-gerais-das-ccbs-do-consignado.pdf';
import pdfPremiumInsuranceConditions from '../../../assets/pdf/condicoes-gerais-do-seguro-prestamista.pdf';
import pdfOmbudsman from '../../../assets/pdf/politica-de-ouvidoria.pdf';
import pdfPrivacy from '../../../assets/pdf/politica-de-privacidade.pdf';
import pdfCredit from '../../../assets/pdf/politica-de-risco-de-credito.pdf';
import pdfOperational from '../../../assets/pdf/politica-de-risco-operacional.pdf';
import pdfAmbiental from '../../../assets/pdf/politica-de-risco-socioambiental.pdf';
import pdfSecurity from '../../../assets/pdf/politica-de-seguranca-da-informacao.pdf';
import pdfScr from '../../../assets/pdf/sistema-de-informacao-de-credito.pdf';
import pdfFeesAndFariffs from '../../../assets/pdf/taxas-e-tarifas.pdf';
import pdfPrestamistaGazin from '../../../assets/pdf/condicoes-gerais-prestamista-coletivo-gazin.pdf';
import balancos from '../../../assets/pdf/balancos-semestrais.pdf';
import balancete1 from '../../../assets/pdf/balancete-01-2022.pdf';
import balancete2 from '../../../assets/pdf/balancete-02-2022.pdf';
import balancete3 from '../../../assets/pdf/balancete-03-2022.pdf';
import balancete4 from '../../../assets/pdf/balancete-04-2022.pdf';
import balancete5 from '../../../assets/pdf/balancete-05-2022.pdf';
import balancete6 from '../../../assets/pdf/balancete-06-2022.pdf';

export const links = {
  legal: [
    {
      href: pdfOmbudsman,
      title: "Política de Ouvidoria",
    },
    {
      href: pdfPrivacy,
      title: "Política de Privacidade",
    },
    {
      href: pdfCredit,
      title: "Política de Risco Crédito",
    },
    {
      href: pdfOperational,
      title: "Política de Risco Operacional",
    },
    {
      href: pdfAmbiental,
      title: "Política de Risco Socioambiental",
    },
    {
      href: pdfSecurity,
      title: "Política de Risco Segurança da Informação",
    },
    {
      href: pdfScr,
      title: "Sistema de Informação de Crédito",
    },
    {
      href: pdfFeesAndFariffs,
      title: "Taxas e Tarifas",
    },
    {
      href: pdfCCBConditions,
      title: "Condições Gerais das CCBs do Consignado",
    },
    {
      href: pdfPremiumInsuranceConditions,
      title: "Condições Gerais do Seguro Prestamista Zurich",
    },
    {
      href: pdfPrestamistaGazin,
      title: "Condições Gerais do Seguro Prestamista Gazin",
    },
  ],
  statements: [
    {
      href: balancos,
      title: "Balanços Semestrais",
    },
    {
      href: balancete1,
      title: "Balancete Mensal 01-2022",
    },
    {
      href: balancete2,
      title: "Balancete Mensal 02-2022",
    },
    {
      href: balancete3,
      title: "Balancete Mensal 03-2022",
    },
    {
      href: balancete4,
      title: "Balancete Mensal 04-2022",
    },
    {
      href: balancete5,
      title: "Balancete Mensal 05-2022",
    },
    {
      href: balancete6,
      title: "Balancete Mensal 06-2022",
    },
  ],
};

export const content = [
  {
    id: 0,
    title: "Legal",
    subtitle: "Políticas e Tarifas",
  },
  // {
  //   id: 1,
  //   title: "Demonstrações Financeiras",
  //   subtitle: "Balanços e Balancetes",
  // },
];