import React, { useEffect } from "react";

import GlobalStyle from "../../assets/styles/global";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import Header from "../Header";
import Footer from "../Footer";

import { ILayoutProps } from "./interface";
import { Helmet } from "react-helmet";

declare global {
  interface Window {
    octadesk: any; // Replace 'any' with the actual type of 'octadesk'
  }
}

const Layout = ({ children }: ILayoutProps) => {

  useEffect(() => {
    window.octadesk = window.octadesk || {};
    window.octadesk.chatOptions = {
      subDomain: 'zipdin',
      showButton: 'true',
      openOnMessage: 'false',
      hide: 'false'
    };
  }, []);

  return (
    <>
      <GatsbySeo
        title="Zipdin - A gente acredita no seu negócio"
        description="Somos uma Fintech que nasceu para transformar o crédito e mudar a vida de pessoas e empresas através da tecnologia."
      />
      <GlobalStyle />
      <Header />
      {children}
      <Footer />
      <Helmet>
        {/* <script src="https://cdn.octadesk.com/embed.js" crossOrigin="anonymous" async></script> */}
      </Helmet>
    </>
  );
};

export default Layout;
