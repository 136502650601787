import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.footer`
  background-color: ${({ theme }) => theme.bg.seventh};
  color: ${({ theme }) => theme.font.white};
  overflow: hidden;
  padding: 80px 0;
  display: flex;
  justify-content: space-between;

  ${mediaQuery.desktop`
    padding: 80px 6rem;
  `}

  .first-colunm-footer {
    margin-bottom: 55px;

    svg {
      margin-right: 5px;

      path {
        transition:  all .2s ease-in-out;
      }
    }

    h1,h2 {
      color: ${({ theme }) => theme.font.white};
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      margin-bottom: 10px;
      span {
        display: block;
        text-transform: none;
      }
    }

    h2 {
      font-size: 13px;
    }

    ul {
      margin-bottom: 50px;

      ${mediaQuery.desktop`
        margin-bottom: 0;
      `}
    }

    li,address,a {
      margin-bottom: 10px;

      a,
      a:active {
        color: ${({ theme }) => theme.font.white};
        transition: 0.2s;
        font-size: 13px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;

        &:hover {
          color: ${({ theme }) => theme.font.fourth};

          svg {
            path {
              /* fill: ${({ theme }) => theme.font.fourth}; */
            }
          }
        }
      }
    }

    ul,address {
      margin-bottom: 50px;

      ${mediaQuery.desktop`
        margin-bottom: 0;
      `}
    }

    p, .linkdefault a {
      margin-bottom: 10px;
      display: flex;
      color: ${({ theme }) => theme.font.white};
      transition:  all .2s ease-in-out;
      font-size: 13px;
      font-weight: 400;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      justify-content: flex-start;
      align-items: center;

      svg {
        fill: ${({ theme }) => theme.font.white};
      }

      &:hover {
        color: ${({ theme }) => theme.font.fourth};

        svg {
          fill: ${({ theme }) => theme.font.fourth};
        }
      }
    }

    h3 {
      margin-bottom: 0.8rem;
    }
  }

  .second-colunm-footer {
    display: block;
    padding-bottom: 50px;
    border-bottom: 1px solid #fff2;
    margin-bottom: 50px;
    font-size: 13px;
    text-align: justify;
    
    ${mediaQuery.desktop`
        display: flex;
        justify-content: space-between;
        text-align: inherit;
    `}

    span {
      display: block;
      margin-top: 40px;

      ${mediaQuery.desktop`
        display: inherit;
        margin-top: inherit;
      `}
    }

    img {
      margin-top: 50px;
    }

    .col-md-1 {
      margin: 50px 0 0;

      ${mediaQuery.desktop`
        margin: inherit
      `}
      
    }
  }

  .third-colunm-footer {
    display: block;

    ${mediaQuery.desktop`
      display: flex;
    `}

    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:last-child {
        margin-top: 40px;

        ${mediaQuery.desktop`
          margin-top: 0;
        `}
      }

      &:first-child {
        figure {
          margin-right: 38px;

          ${mediaQuery.desktop`
            margin-right: 20px;
          `}
        }
      }

      figure {
        margin-right: 20px;
      }

      .description-abcd {
        max-width: 384px;
      }

    }

    p {
      font-size: 11px;
      font-weight: 300;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      color: #fff8;
    }
  }
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  align-items: center;
  justify-content: center;
  margin-top: 7vh;

  ${mediaQuery.desktop`
        margin-top: 0vh;
    `}
    
  figure {
    margin: 0vh 3vw;
    
    ${mediaQuery.desktop`
      margin: 0vh 0vw 0vh 1.5vw;
      `}
  }
`;
