import React from 'react';
import { Link } from 'gatsby';

import icLogo from '../../assets/images/logotipo.svg';
import icArrowDown from '../../assets/images/ic_arrow-down.svg';
import icClose from '../../assets/images/ic_close.svg';
import icLogoWhite from '../../assets/images/ic_logo-zipdin-white.svg';
import icMenuMobile from '../../assets/images/ic_menu-mobile.svg';

import Button from '../Button';

import { Container } from './styles';

const Header: React.FC = () => {
  const [openMenu, setOpenMenu] = React.useState(false);

  const handleNavigationPortal = React.useCallback((): void => {
    window.open('https://zipconsig.zipdin.com.br/login')
  }, []);


  return (
    <Container openMenu={openMenu} >
      <Link to="/" className="header-logo">
        <img src={icLogo} alt="Logo da zipban" />
      </Link>
      <nav className="header-nav">
        <div className="header__submenu-mobile">
          <Link to="/">
            <img src={icLogoWhite} alt="Logo da zipban" />
          </Link>
          <button className="header__button-close" onClick={() => setOpenMenu(false)}>
            <img src={icClose} alt="Logo da zipban" />
          </button>
        </div>
        <ul className="menu-list-header">
          <li>
            <Link onClick={() => setOpenMenu(!openMenu)} to="/quem-somos/"> Quem somos </Link>
          </li>
          <li>
            <Link to="#">
              <span className="is-opacity">
                As a service
              </span>
              <img
                className="ic-arrow"
                src={icArrowDown}
                alt="icone de uma seta para baixo"
              />
            </Link>
            <ul className="header__submenu is-hover">
              <li>
                <Link onClick={() => setOpenMenu(!openMenu)} to="/bank-as-a-service"> Bank as a Service </Link>
              </li>
              <li>
                <Link onClick={() => setOpenMenu(!openMenu)} to="/consignado-as-a-service">
                  Consignado as a Service
                </Link>
              </li>
              <li>
                <Link onClick={() => setOpenMenu(!openMenu)} to="/credito-pj-as-a-service">
                  Crédito PJ as a Service
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link onClick={() => setOpenMenu(!openMenu)} to="/credito-consignado-privado">
              Crédito Consignado Privado
            </Link>
          </li>
          <li>
            <Link onClick={() => setOpenMenu(!openMenu)} to="/contato/"> Contato </Link>
          </li>
          <li>
            <Link onClick={() => setOpenMenu(!openMenu)} to="/corporation/"> Governança Corporativa </Link>
          </li>
        </ul>
        <Button onClick={handleNavigationPortal} className="header__button-singin" hiddenIcon={false}>
          Entrar
          </Button>
      </nav>
      <Button
        className="button-header"
        type="button"
        onClick={handleNavigationPortal}>
        Entrar
        <img src={icMenuMobile} alt="icone da zipdin" />
      </Button>
      <Button
        hiddenIcon={true}
        className="button-header-mobile"
        onClick={() => setOpenMenu(true)}>
        <img className="header__btn-img" src={icMenuMobile} alt="icone da zipdin" />
      </Button>
    </Container>
  );
};

export default Header;
